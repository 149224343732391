import BaseService from "../../base/base.service";
import axios from "axios";

class SaleItemService extends BaseService {
    get modelName() {
        return "SaleItem"
    }
    create({sale, saleItem}) {
        return axios.post(this.getCreateUrl(), {
            SaleId: sale.SaleId,
            ItemId: saleItem.Item.Item.ItemId,
            Quantity: +saleItem.Quantity,
            DiscountPercent: saleItem.DiscountPercent,
            CurrencyValue: {
                Value: saleItem.Item.Item.PriceCurrencyValue.Value,
                Rate: saleItem.Item.Item.PriceCurrencyValue.Rate,
                CurrencyId: saleItem.Item.Item.PriceCurrencyValue.CurrencyId
            },
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    delete(id) {
        return axios.delete(this.getDeleteUrl(id));
    }

    edit(saleItemId, {sale, saleItem}) {
        return axios.put(this.getEditUrl(saleItemId), {
            SaleId: sale.SaleId,
            ItemId: saleItem.ItemId,
            Quantity: saleItem.Quantity,
            CurrencyValue: {
                Value: saleItem.CurrencyValue.Value,
                Rate: saleItem.CurrencyValue.Rate,
                CurrencyId: saleItem.CurrencyValue.CurrencyId
            },
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

export default new SaleItemService()