<template>
  <b-overlay spinner-variant="success" opacity="1" :show="loading" id="main">
    <bar />
    <Menu />
    <orders />
    <BottomNav />
  </b-overlay>
</template>

<script>

import bar from '/src/components/bar.vue'
import menu from '/src/components/menu.vue'
import orders from '/src/components/orders.vue'
import BottomNav from '/src/components/BottomNav'
import WarehouseService from "../../../services/product/warehouse.service";
import {mapGetters} from "vuex";
export default {
  name: 'App',
  components: {
    "bar":bar,
    "Menu":menu,
    orders,
    BottomNav
  },
  computed: {
    ...mapGetters({
      loading: 'getLoading'
    })
  },
  mounted() {
    this.loadWarehouses()
  },
  methods: {
    loadWarehouses() {
      let vm = this;
      vm.$store.commit('onLoading')
      let params = {
        skip: 0,
        limit: null,
        query: '',
        sort: null,
        desc: null
      };
      WarehouseService.getAll(params).then(function (warehouses) {
        vm.$store.commit('offLoading')
        vm.$store.commit('setWarehouses', {warehouses: warehouses})
        if(!localStorage.getItem('selectedWarehouse')) {
          vm.$store.commit('selectWarehouse', {warehouse: warehouses[0]});
          localStorage.setItem('selectedWarehouse', JSON.stringify(warehouses[0]));
        } else {
          let SW = localStorage.getItem('selectedWarehouse')
          vm.$store.commit('selectWarehouse', {warehouse: JSON.parse(SW)})
        }
      });
    }
  },
}
</script>

<style>
body {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  padding: 0;
}
</style>
