import Vue from "vue";
import VueRouter from "vue-router";
import CheckAccessMiddleware from "../src/utils/CheckAccessMiddleware";

import dashboard from '/src/views/app/dashboard'
import Login from "../src/views/user/Login";
import user from "/src/views/user";

Vue.use(VueRouter);

const routes = [
    {
        path: 'app/dashboard',
        name: 'dashboard',
        component: dashboard,
        meta: {auth: true},
    },
    {
        path: '/user',
        redirect: '/user/login',
        meta: {auth: false},
        component: user,
        children: [
            {
                path: 'login',
                name: 'login',
                meta: {auth: false},
                component: Login,
            },
        ]
    },
]

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
    mode: "hash"
});

router.beforeEach(CheckAccessMiddleware);
export default router;

